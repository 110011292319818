
/// BOOTSTRAP (4.6) CSS - SITE CSS
import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import './node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import './main.css';

// JQUERY
import './node_modules/jquery/dist/jquery.min.js';

//import './node_modules/@fortawesome/fontawesome-free';
import './node_modules/@fortawesome/fontawesome-free/css/all.min.css';

var jQuery = require("jQuery");
window.$ = window.jQuery = jQuery;